import React from "react"

const Star = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
    >
      <path
        id="iconmonstr-star-3"
        d="M12,.587l3.668,7.294L24,8.99l-6.064,5.617,1.48,7.979L12,18.764,4.583,22.587l1.481-7.979L0,8.99,8.332,7.881Z"
        transform="translate(0 -0.587)"
        fill="#fafafa"
      />
    </svg>
  )
}

export default Star
