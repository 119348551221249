import dayjs from "dayjs"
import customParseFormat from "dayjs"
import "dayjs/locale/pl"

const formatDate = incoming_date => {
  dayjs.extend(customParseFormat)
  return incoming_date
    ? dayjs(incoming_date).locale("pl").format("DD MMMM YYYY")
    : null
}

export default formatDate
