import "./styles.scss"

import React from "react"

import Star from "components/Icons/Star"

import formatDate from "utils/formatDate"

const TestimonialContent = ({ testimonial }) => {
  const { date, name, review, score, screenshot, source } = testimonial

  const getSourceLogo = source => {
    switch (source) {
      case "Google":
        return (
          <img
            src={require("assets/icons/testimonials/google.svg").default}
            alt=""
          />
        )
      case "Booksy":
        return (
          <img
            src={require("assets/icons/testimonials/booksy.svg").default}
            alt=""
          />
        )
      case "Facebook":
        return (
          <img
            src={require("assets/icons/testimonials/facebook.svg").default}
            alt=""
          />
        )
      default:
        return null
    }
  }

  const source_logo = getSourceLogo(source)

  return (
    <section className="single-testimonial">
      <div className="container">
        <div className="single-testimonial__content">
          <div className="single-testimonial__info">
            <p className="single-testimonial__name">{name}</p>
            <p className="single-testimonial__review">{review}</p>
            <div className="single-testimonial__info-item">
              <p className="single-testimonial__desc">Ocena</p>
              <div className="single-testimonial__score">
                {Array(score)
                  .fill("")
                  .map((item, index) => (
                    <Star key={index} />
                  ))}
              </div>
            </div>
            <div className="single-testimonial__info-item">
              <p className="single-testimonial__desc">Data dodania</p>
              <div className="single-testimonial__date">{formatDate(date)}</div>
            </div>
            <div className="single-testimonial__info-item">
              <p className="single-testimonial__desc">Opinia dodana poprzez</p>
              <div className="single-testimonial__source">{source_logo}</div>
            </div>
          </div>
          <div className="single-testimonial__screenshot">
            <img src={screenshot.sourceUrl} alt={screenshot.altText} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialContent
