import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import TestimonialContent from "page_components/single-testimonial/TestimonialContent"
import TestimonialsSlider from "components/TestimonialsSlider"

const SingleTestimonial = ({ pageContext }) => {
  const breadcrumbs_data = [
    {
      name: "Opinie",
      href: "/opinie/",
    },
    {
      name: pageContext.data.title,
      href: `/opinie/${pageContext?.data?.slug}/`,
    },
  ]

  return (
    <Layout
      seo={{
        title: pageContext?.data?.seo?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header={pageContext?.data?.title} />
      <TestimonialContent testimonial={pageContext?.data?.acfTestimonials} />
      <TestimonialsSlider title="" show_more_btn={true} />
    </Layout>
  )
}

export default SingleTestimonial
